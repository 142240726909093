import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';

const ContactPage = () => (
  <Layout>
    <Seo title="Kontakt" />
    <Hero>
      <h1>Kontakt</h1>
      <p className="lead">Für nähere Auskünfte kontaktieren Sie uns. Wir sind für Sie da.</p>
    </Hero>

    <div className="container-xl">
      <div className="row">
        <div className="col">
          <h2>Anschrift</h2>
          <address>
            projekt spielart GmbH<br/>
            Krainstr. 49b<br/>
            83026 Rosenheim<br/>
            <a href="tel:+49 8031 41 858">Telefon: +49 (0) 8031 41 858</a><br/>
            <a href="mailto:info@projekt-spielart.de">info@projekt-spielart.de</a>
          </address>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;